import React from "react";
import Button from "../../Button";
import bannerImg3 from "../../../img/OandaImages/hero-business-oanda-desktop.png";
import styles from "./index.module.scss";

const OandaMainBanner = () => {
    return (
        <div className="OandaBanner">
            <div className={"container"}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-wrapper-content">
                            <span className={styles.subHeading}>Oanda FX Payments</span>
                                <h1 className={styles.heading}> FX Risk Management, Global Payments, & Multi-Currency Accounts</h1>
                                <p className={styles.description}>Find cost effective FX and risk management solutions with OANDA FX Payment.Your personal account manager will help you safely negotiate the currency markets to maximize value and minimize risk. You will be presented with cost effective FX and risk management solutions that are in keeping with your business’ trade flows and risk appetite. As an OANDA partner, you will benefit from our extensive range of FX and cash management offerings that are easily tailored to suit your company structure and financial goals. You can expect upfront and transparent communication from start to finish.</p>
                             <div className={styles.ctaWrapper}>
					                    <Button
						                href={ "https://shiftconnect.typeform.com/to/JBq1YUaP"}
						                color={"oanda"}
						                className={styles.getStartedButton}
					                     >
						                 Get started 
					                    </Button>
					                <span className={styles.signIn}>
						                Using Oanda FX Payments?{" "}
						             <a href="https://oanda.paydirect.io/login" className={styles.signInLink}>
							            Sign in
						             </a>
					                </span>
				             </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                            <div >
                                <img src={bannerImg3} class="img-fluid" alt="banner" />
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OandaMainBanner