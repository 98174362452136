import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import Heading from "../../Heading";

import styles from "./index.module.scss";

class FaqBucketGroupOanda extends React.Component {
	render() {
		return (
			<Container >
				<Row className={styles.bucketGroupRow}>
					<Col>
						<Row>
							<Col className={styles.bucketGroupHeading}>
								
								<Heading
									text={this.props.headingText}
									themeType={this.props.themeType}
								/>
							</Col>
						</Row>
						<Row>{this.props.children}</Row>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default FaqBucketGroupOanda;