import React from "react";
import { Container } from "react-bootstrap";

import Layout from "../../components/OandaComponents/LayoutOanda";
import Background from "../../components/Background";
import BucketGroupSticky from "../../components/BucketGroupSticky";
import BucketForSticky from "../../components/BucketForSticky";
import FxAssessmentOanda from "../../components/OandaComponents/FxAssessmentOanda";
import FAQ from "../../components/OandaComponents/FaqOanda";
import LoginSignUpOanda from "../../components/OandaComponents/LoginSignupOanda";
import OandaHeader from "../../components/OandaComponents/OandaHeader";

import styles from "../oanda/index.module.scss";

import iconFXExpertise from "../../img/OandaImages/OandaFXExpertise.png";
import iconGlobal from "../../img/OandaImages/OandaGlobalPayments.png";
import iconRiskManagement from "../../img/OandaImages/OandaRiskManagement.png";
import iconMultiCurrency from "../../img/OandaImages/LoginSignUp-Login.png";






const Oanda = () => {
    return (
      <Layout>
        <Background themeType={"oanda"}>
          <OandaHeader> 
          </OandaHeader>
          <Container className={styles.oandaMethodsSection}>
             <div className="col-lg-12 col-md-12">
                <h2>Why partner with OANDA FX payments?</h2>
                <p>
                  Make multi-currency payments at the click of a button. You can leverage our competitive rates and sophisticated global payments platform and our team of experienced FX professionals who create robust hedging strategies in fitting with your unique needs, goals, and challenges in the global marketplace. You will also benefit from our connections with payment systems all around the world which allow you to pay your suppliers, employees, and business partners in the currency that is right for them and you. With OANDA FX Payments, you can trust that your payments will always arrive on time. 
                </p>
              </div> 
          </Container>
          <Container className={styles.oandaMethodsSection}>
            <BucketGroupSticky 
              headingText= { "Industry-leading provider, unparalleled platform"}
              themeType={"oanda"}
            >
              <BucketForSticky
                imageURL={iconFXExpertise}
                title={"Money transfers for business"}
                description={"A simple-to-use and reliable international money transfer solution for businesses. Our technology lets you process payments and make FX transfers in real time."}
                themeType={"oanda"}
              />
              
              <BucketForSticky
                imageURL={iconGlobal}
                title={" Hedging and treasury management "}
                description={"A customized hedging strategy that assesses the complexities of your business. We work with you to protect your company from risk."}
                themeType={"oanda"}
              />
    
              <BucketForSticky
                imageURL={iconRiskManagement}
                title={"Borderless accounts "}
                description={"Get the account your business needs. We offer our service in numerous countries worldwide in the currency your clients want." }
                themeType={"oanda"}
              /> 

              <BucketForSticky
                imageURL={iconMultiCurrency}
                title={"Multi-currency capabilities"}
                description={"Simplify your operations by receiving money globally without the need for multiple bank accounts or a local presence. Gain a competitive advantage and increase customer satisfaction by pricing and accepting payments in the currency your clients prefer. View the funds received online. Repatriate funds at competitive FX rates and make payments quickly and securely." }
                themeType={"oanda"}
              /> 
            </BucketGroupSticky>
          </Container>
          <FxAssessmentOanda />
          <FAQ />  
          <LoginSignUpOanda />
        </Background>
      </Layout>
    );
  };
  
  export default (Oanda);