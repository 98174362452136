import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Heading from "../../Heading";

import styles from "./index.module.scss";

import iconSignUp from "../../../img/OandaImages/LoginSignUp-SignUp.png";
import iconLogin from "../../../img/OandaImages/LoginSignUp-Login.png";

class LoginSignUpOanda extends React.Component {
	render() {
		return (
			<Container className={styles.fixedContainer}>
				<Row>
					<Col className={styles.headingColumn}>
						<Heading text={"Solutions to suit your needs"} />
					</Col>
				</Row>
				<Row>
					<Col
						sm={12}
						md={true}
						className={styles.solution}
						onClick={() => {
							window.location =
								"https://shiftconnect.typeform.com/to/JBq1YUaP?typeform-source=www.shiftconnect.com";
						}}
					>
						<div className={styles.imageWrapper}>
							<img
								src={iconLogin}
								width="42"
								height="42"
								alt=""
							/>
						</div>
						<div className={styles.contentWrapper}>
							<h2 className={styles.title}>Log In</h2>
							<p>
					        Our team of experts, bespoke technology, and simple platform are at your service.
							</p>

							<div className={styles.cta}>
								<span>Log In</span>
							</div>
						</div>
					</Col>
					<Col
						sm={12}
						md={true}
						className={styles.solution}
						onClick={() => {
							window.location =
								"https://shiftconnect.typeform.com/to/JBq1YUaP?typeform-source=www.shiftconnect.com";
						}}
					>
						<div className={styles.imageWrapper}>
							<img
								src={iconSignUp}
								width="42"
								height="42"
								alt=""
							/>
						</div>
						<div className={styles.contentWrapper}>
							<h2 className={styles.title}>Sign Up</h2>
							<p>
							Join our diverse client base to take advantage of our best-in-class FX solutions.
							</p>
							<div className={styles.cta}>
								<span>Join Now</span>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default LoginSignUpOanda;