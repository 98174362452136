import React from "react";

import FaqBucket from "../BucketFAQOanda";
import FaqBucketGroupOanda from "../../OandaComponents/FaqBucketGroupOanda";


class FaqOanda extends React.Component {
    render() {
	    return (
            
                <FaqBucketGroupOanda
                 headingText={"FAQ"}
                 

                >
                    <FaqBucket
                        title={"What information do I need to provide to make a transfer?"}
                    preamble={
                    "Information that is required for your payment depends on the payment type (local or standard/SWIFT payment),"
                    }
                    >
                        <p>
                        Information that is required for you to make a payment depends on the payment type (local or standard/SWIFT payment), payer country, payer legal entity type, beneficiary country, beneficiary entity type and payment destination country.
                        </p>
                    </FaqBucket>
                    <FaqBucket
                    title={"How secure is Oanda FX Payments platform?"}
                        preamble={
                    "Our platform and operational security are certified under ISO/IEC 27001:2013, the international best practice standard."
                    }
                    >
                        <p>
                        For Information Security Management Controls which is independently audited. Put simply, your security is our top priority and we take it very seriously. We pride ourselves on the safety and security of our best-in-class platform. 
                        </p>
                    </FaqBucket>
                    <FaqBucket
                    title={"Does the recipient also need to be an OANDA account holder?"}
                        preamble={
                    "You are free to send funds through your  account to any individual or company that has a bank account. "
                    }
                    >
                    </FaqBucket>
                </FaqBucketGroupOanda>  
        );
    }    
}

export default FaqOanda;