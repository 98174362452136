import React from "react";
import classNames from "classnames/bind";
import { Col } from "react-bootstrap";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class FaqBucket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      learnMore: false
    };
  }

  showMore() {
    this.setState({ learnMore: !this.state.learnMore });
  }

  render() {
    let buttonText = this.state.learnMore ? "Hide" : "Learn More";
    let learnMoreContentClasses = classNamesBound({
      learnMoreContent: true,
      show: this.state.learnMore
    });

    return (
      <Col sm={12} md={4} className={styles.bucket}>
        <div className={styles.contentWrapper}>
          <h2 className={styles.title}>{this.props.title}</h2>
          <p>{this.props.preamble}</p>
          <div className={learnMoreContentClasses}>{this.props.children}</div>
          <button
            onClick={() => this.showMore()}
            className={styles.learnMoreButton}
          >
            {buttonText}
          </button>
        </div>
      </Col>
    );
  }
}

export default FaqBucket;