import React from "react";
import { Row, Col } from "react-bootstrap";

import Section from "../../Section";
import Button from "../../Button";

import styles from "./index.module.scss";

import OandaFXassessment from "../../../img/OandaImages/OandaFXassessment.png";

class FxAssessmentOanda extends React.Component {
	render() {
		return (
			<Section className={styles.fxassessmentSection}>
				<Row className={styles.badge}>
					<Col>
						<Row>
							<Col
								xs={12}
								lg={true}
								className={styles.headingColumn}
							>
								<div className={styles.headingWrapper}>
									<img
										src={OandaFXassessment}
										className={styles.icon}
										width="65"
										height="65"
										alt=""
									/>
									<h2 className={styles.heading}>
									    Personalised FX assessment tool
									</h2>
								</div>
							</Col>
							<Col xs={12} lg={true}>
								<p className={styles.paragraph}>
								How effective are your company’s’ FX practices? Use our free assessment tool to gauge how efficiently you are managing foreign exchange risk. 
								</p>
								<Button
									href= "https://shiftconnect.typeform.com/to/qYvv1k5t"
									className={styles.button}
								>
									Assess your business
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Section>
		);
	}
}

export default FxAssessmentOanda;